<template>
  <div class="comp-per-mod">
    <div class="list-box-wrap">
      <div class="title">
        <div class="text">近7日获得TOP10</div>
        <div class="price">$88.88</div>
      </div>
      <div class="list-box2">
        <div class="per-item" v-for="(item, index) in list" :key="index">
          <img :src="item.goodImg" alt="" class="good-img" />
          <div class="good-name ellipsis">{{ item.name }}</div>
          <img
            src="../../../assets/images/OpenBox/popup/icon-box.png"
            alt=""
            class="icon"
          />
        </div>
      </div>
    </div>
    <div class="info-box">
      <div class="info-l">
        <div class="info-item">
          <img src="../../../assets/images/OpenBox/popup/info-box.png" alt="" />
          <img
            src="../../../assets/images/OpenBox/popup/title-box.png"
            class="info-title"
            alt=""
          />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
        <div class="info-item">
          <img
            src="../../../assets/images/OpenBox/popup/info-battle.png"
            alt=""
          />
          <img
            src="../../../assets/images/OpenBox/popup/battle.png"
            class="info-title"
            alt=""
          />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
        <div class="info-item">
          <img
            src="../../../assets/images/OpenBox/popup/info-luck.png"
            alt=""
          />
          <img
            src="../../../assets/images/OpenBox/popup/title-luck.png"
            class="info-title"
            alt=""
          />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="info-r">
        <div class="info-r-wrap">
          <img
            src="../../../assets/images/OpenBox/popup/title-data.png"
            alt=""
            class="title-data"
          />
          <div class="data-box">
            <div class="data1">盲盒饰品</div>
            <div class="pig">
              <div class="pig-l">盲盒对战</div>
              <div class="pig-c">
                <div></div>
              </div>
              <div class="pig-r">道具取回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.comp-per-mod {
  .list-box-wrap {
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 0.16rem;
      font-weight: 400;
      .price {
        font-weight: 500;
        color: #db562a;
        margin-right: 0.2rem;
      }
      .text {
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cccccc;
      }
    }
    .list-box2 {
      display: flex;
      flex-wrap: wrap;
    }
    .per-item {
      width: 1.95rem;
      height: 1.3rem;
      background: url(../../../assets/images/OpenBox/popup/item-box.png)
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      color: #fff;
      font-size: 0.15rem;
      text-align: center;
      margin-top: 0.2rem;
      margin-right: 0.17rem;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .good-img {
        width: 80%;
        height: 80%;
        margin: 0 auto;
        display: block;
      }
      .icon {
        position: absolute;
        top: 0.1rem;
        right: 0.09rem;
        width: 0.25rem;
      }
    }
  }
  .info-box {
    display: flex;
    .info-l {
      width: 70%;
      .info-item {
        display: flex;
        background: linear-gradient(180deg, #083a5e, #1a5885);
        border: 0.01rem solid #0d68a5;
        margin-top: 0.43rem;
        box-sizing: border-box;
        padding: 0.19rem 0.25rem;
        display: flex;
        .info-title {
          width: 2rem;
          height: 0.45rem;
          align-self: center;
          margin-left: 0.1rem;
        }
        .text,
        .text-val {
          font-size: 0.16rem;
          color: #fff;
          font-weight: 400;
          align-self: center;
        }
        .text {
          margin-left: 0.8rem;
        }
        .text-val {
          flex: 1;
          text-align: center;
        }
      }
    }
    .info-r {
      width: 30%;
      height: 100%;
      box-sizing: border-box;
      padding: 0 0.2rem;
      .info-r-wrap {
        width: 100%;
        height: 6rem;
        background: linear-gradient(#115498, #02366b);
        overflow: hidden;
        margin-top: 0.43rem;
        .title-data {
          display: block;
          margin-top: 0.2rem;
          width: 100%;
        }
        .data-box {
          font-size: 0.12rem;
          text-align: center;
          .pig {
            display: flex;
            .pig-l,
            .pig-r {
              width: 0.8rem;
            }
            .pig-c {
              flex: 1;
              height: 1rem;
              box-sizing: border-box;
              -webkit-clip-path: polygon(
                50% 0%,
                100% 38%,
                82% 100%,
                18% 100%,
                0% 38%
              );
              clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
              background: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              div {
                width: 75%;
                height: 75%;
                background: #2c5f93;
                -webkit-clip-path: polygon(
                  50% 0%,
                  100% 38%,
                  82% 100%,
                  18% 100%,
                  0% 38%
                );
                clip-path: polygon(
                  50% 0%,
                  100% 38%,
                  82% 100%,
                  18% 100%,
                  0% 38%
                );
              }
            }
          }
        }
      }
    }
  }
}
</style>
