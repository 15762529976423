var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-open-box-new",style:({ position: 'fixed', left: '50%', top: _vm.topHeight + 'rem' })},[_c('div',{staticClass:"title-box"},[_vm._v("恭喜你获得")]),_c('div',{staticClass:"getSome-box"},[_c('div',{staticClass:"a-show-box"},_vm._l((_vm.imgList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:('item.lv != undefined'),expression:"'item.lv != undefined'"}],key:index,staticClass:"img-item point",class:[
          item.lv != undefined
            ? `bg-${item.lv}`
            : item.bean > 1
            ? 'bg-1'
            : 'bg-4',
        ],style:(_vm.openNum === 4?'width:1.32rem':'width:1.18rem'),on:{"click":function($event){return _vm.chooseItemOnClick(item)}}},[(_vm.chooseList.includes(item.id))?_c('div',{staticClass:"img-item-active"}):_vm._e(),(!_vm.isCdkeyBox)?_c('div',{staticClass:"alias"},[_vm._v(_vm._s(item.dura_alias))]):_vm._e(),(_vm.isCdkeyBox)?_c('div',{staticClass:"top-img-box"},[_c('img',{staticStyle:{"height":"110%"},attrs:{"src":require("@/assets/newImages/moeny-pile.png"),"alt":""}})]):_c('div',{staticClass:"top-img-box"},[_c('img',{staticStyle:{"height":"110%"},attrs:{"src":item.cover,"alt":""}})]),(!_vm.isCdkeyBox)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('div',{staticClass:"price"},[_c('Cint',{staticClass:"price-bean",attrs:{"price":item.bean}})],1)])}),0),_c('div',{staticClass:"bottom-box"},[_c('div',{staticClass:"cancel point",on:{"click":_vm.cancelFun}},[_c('Cint',{staticClass:"cancel-price",attrs:{"price":_vm.totalPrice}}),_c('div',{staticStyle:{"margin-left":"0.06rem"}},[_vm._v("回收")])],1),_c('div',{staticClass:"confirm point",on:{"click":_vm.confirmFun}},[_vm._v("放入背包")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }