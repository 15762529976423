import pro0 from "@/assets/images/OpenBox/pro0.png";
import pro1 from "@/assets/images/OpenBox/pro1.png";
import pro2 from "@/assets/images/OpenBox/pro2.png";
import pro3 from "@/assets/images/OpenBox/pro3.png";
import swiper1 from "@/assets/images/home/home-banner-swiper1.png";
import playing1 from "@/assets/images/home/playing/playing-1.png";
import playing2 from "@/assets/images/home/playing/playing-2.png";
import playing3 from "@/assets/images/home/playing/playing-3.png";
import playing4 from "@/assets/images/home/playing/playing-4.png";
import playingActive1 from "@/assets/images/home/playing/playing-1-active.png";
import playingActive2 from "@/assets/images/home/playing/playing-2-active.png";
import playingActive3 from "@/assets/images/home/playing/playing-3-active.png";
import playingActive4 from "@/assets/images/home/playing/playing-4-active.png";
import playingInfo1 from "@/assets/images/home/playing/playing-info1.png";
import playingInfo2 from "@/assets/images/home/playing/playing-info2.png";
import playingInfo3 from "@/assets/images/home/playing/playing-info3.png";
import playingInfo4 from "@/assets/images/home/playing/playing-info4.png";
import playingInfoActive1 from "@/assets/images/home/playing/playing-info-active-1.png";
import playingInfoActive2 from "@/assets/images/home/playing/playing-info-active-2.png";
import playingInfoActive3 from "@/assets/images/home/playing/playing-info-active-3.png";
import playingInfoActive4 from "@/assets/images/home/playing/playing-info-active-4.png";
export const proBList = [pro0, pro1, pro2, pro3];
export const tableRecordList = [
  // {
  //   title: "掉落时间",
  //   width: "40%",
  //   index: "created_at",
  //   center: "center",
  //   render: true,
  // },
  {
    title: "获得玩家",
    width: "35%",
    index: "get_user",
    center: "center",
    render: true,
  },
  // {
  //   title: "游戏类型",
  //   width: "32%",
  //   index: "type",
  //   center: "center",
  //   render: true,
  // },
  {
    title: "道具名称",
    width: "45%",
    index: "name",
    center: "center",
    render: true,
  },
  {
    title: "T币",
    width: "10%",
    index: "bean",
    center: "center",
    render: true,
  },
];
export const tableData = {
  // total: 15,
  list: [
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
export const statisticsList = [
  {
    title: "道具统计",
    width: "70%",
    index: "get",
    center: "center",
    render: true,
  },
  {
    title: "距离上次开出",
    width: "28%",
    index: "num",
    center: "center",
    rightVal: "次",
  },
  {
    title: "总开出",
    width: "15%",
    index: "total",
    center: "center",
    rightVal: "次",
  },
];
export const statisticsData = {
  total: 15,
  list: [
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
export const typeList = [
  {
    title: "道具类型",
    width: "30%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "理论概率",
    width: "25%",
    index: "theory",
    center: "center",
    rightVal: "%",
  },
  {
    title: "开出个数",
    width: "25%",
    index: "openNum",
    center: "center",
  },
  {
    title: "目前概率",
    width: "20%",
    index: "currently",
    center: "center",
    render: true,
  },
];
export const typeData = {
  total: 15,
  list: [
    {
      type: 1,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 2,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 3,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 4,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
  ],
};
export const playingList = [
  {
    imgUrl: playing1,
    activeUrl: playingActive1,
    title: playingInfo1,
    titleActive: playingInfoActive1,
    info: "真实高爆",
    page: "/classicsBox",
  },
  {
    imgUrl: playing2,
    activeUrl: playingActive2,
    title: playingInfo2,
    titleActive: playingInfoActive2,
    info: "概率最高75%",
    page: "/Lucky",
  },
  {
    imgUrl: playing3,
    activeUrl: playingActive3,
    title: playingInfo3,
    titleActive: playingInfoActive3,
    info: "你来我就送",
    page: "/Roll",
  },
  {
    imgUrl: playing4,
    activeUrl: playingActive4,
    title: playingInfo4,
    titleActive: playingInfoActive4,
    info: "急速发货",
    page: "/Mall",
  },
];
