import { render, staticRenderFns } from "./luckdrawY.vue?vue&type=template&id=38a20f44&"
import script from "./luckdrawY.vue?vue&type=script&lang=js&"
export * from "./luckdrawY.vue?vue&type=script&lang=js&"
import style0 from "./luckdrawY.vue?vue&type=style&index=0&id=38a20f44&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports