<template>
  <div
    class="a-open-box-new"
    :style="{ position: 'fixed', left: '50%', top: topHeight + 'rem' }"
  >
    <div class="title-box">恭喜你获得</div>
    <div class="getSome-box">
      <div class="a-show-box">
        <div
          @click="chooseItemOnClick(item)"
          :key="index"
          v-for="(item, index) in imgList"
          v-show="'item.lv != undefined'"
          class="img-item point"
          :style="openNum === 4?'width:1.32rem':'width:1.18rem'"
          :class="[
            item.lv != undefined
              ? `bg-${item.lv}`
              : item.bean > 1
              ? 'bg-1'
              : 'bg-4',
          ]"
        >
          <div
            v-if="chooseList.includes(item.id)"
            class="img-item-active"></div>
          <div class="alias"  v-if="!isCdkeyBox">{{ item.dura_alias }}</div>
          <div class="top-img-box" v-if="isCdkeyBox">
            <img
              style="height: 110%"
              src="@/assets/newImages/moeny-pile.png"
              alt=""
            />
          </div>
          <div class="top-img-box" v-else>
            <img style="height: 110%" :src="item.cover" alt="" />
          </div>
          <div class="name" v-if="!isCdkeyBox">
            {{ item.name }}
          </div>
          <div class="price">
            <Cint class="price-bean" :price="item.bean" />
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="cancel point" @click="cancelFun">
          <Cint class="cancel-price" :price="totalPrice" />
          <div style="margin-left: 0.06rem">回收</div>
        </div>
        <div class="confirm point" @click="confirmFun">放入背包</div>
      </div>
    </div>
  </div>
</template>

<script>
// 使用：盲盒开箱展示
export default {
  created() {
    //禁止滚动条滚动
    document.querySelector("html").classList.add("lock");
    window.addEventListener("mousewheel", this.forbidScroll);
    window.addEventListener("touchmove", this.forbidScroll, { passive: false });
  },
  data() {
    return {
      chooseList: [],
      topHeight: 0,
       WidTh:  1.18,  
    };
  },
  props: {
    imgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //开奖倍数
    openNum: {
      type: Number,
    },
    animationShow: {
      type: Boolean,
    },
    isCdkeyBox: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.imgList.forEach((item) => {
      this.chooseList.push(item.id);
    });
    if (this.animationShow) {
      if (this.openNum <= 2) {
        this.topHeight = -1.2;
      } else if (this.openNum >= 3) {
        this.topHeight = -0.8;
      }
    } else {
      if (this.openNum <= 4) {
        this.topHeight = -1.8;
      } else if (this.openNum > 4) {
        this.topHeight = -2.2;
      }
    }
  },
  computed: {
    totalPrice() {
      let JiaZhi = 0;
      for (let k = 0; k < this.chooseList.length; k++) {
        this.imgList.forEach((item) => {
          if (item.id == this.chooseList[k]) {
            JiaZhi += Number(item.bean);
          }
        });
      }
      return JiaZhi.toFixed(2);
    },
  },
  methods: {
    chooseItemOnClick(item) {
      if (!this.chooseList.includes(item.id)) {
        this.chooseList.push(item.id);
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item.id), 1);
      }
    },
    cancelFun() {
      this.$emit("cancelFun", this.chooseList);
    },
    confirmFun() {
      this.$emit("confirmFun", this.chooseList);
    },
  },
};
</script>

<style lang="scss" scoped>
.a-open-box-new {
  width: 100%;
  padding: 0.12rem 0.12rem 0.33rem 0.12rem;
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
  // position: fixed;
  // left: 50%;
  // top: 10%;
  transform: translate(-50%, -50%);
  .title-box {
    width: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.2rem;
    font-weight: 500;
    font-size: 0.18rem;
    color: #ffffff;
    margin-bottom: 0.12rem;
    position: absolute;
    z-index: 100;
    left: -0.19rem;
    top: 0;
  }
  .getSome-box {
    width: 110%;
    position: absolute;
    z-index: 100;
    left: -0.19rem;
    top: 0.62rem;
    // padding: 0 0.32rem;
  }
  .a-show-box {
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: space-evenly;
    // height: 2.2rem;
    margin-bottom: 0.28rem;
    flex-wrap: wrap;

    .img-item {
      // width: 1.41rem;
      // height: 1.41rem;
      // width: 1.18rem;
      // height: 1.32rem;
      padding: 0.07rem 0.08rem;
      margin-bottom: 0.04rem;
      position: relative;
      .img-item-active {
           width: 100%;
           height: 100%;
           position: absolute;
           top: 0;
           left: 0;
          @include bgImg("battle-item-border-bg");
          }
      .alias {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.1rem;
        font-weight: 100;
      }
      .top-img-box {
        // width: 100%;
        // height: 0.5rem;
        // width: 1.28rem;
        height: 0.80rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.02rem;
      }

      .name {
        margin-top: -0.06rem;
        font-size: 0.1rem;
        // max-width: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 0.08rem;
        text-align: center;
        // margin-bottom: 0.04rem;
      }

      .price {
        font-size: 0.1rem;
      }
    }

    .bg-1 {
      background: url("../../assets/newImages/open-bg1.png") no-repeat;
      background-size: 132% 75%;
      background-position: -0.18rem 0.46rem;
      z-index: 99;
    }

    .bg-2 {
      background: url("../../assets/newImages/open-bg2.png") no-repeat;
      // background-size: 120% 120%;
      // background-position: -0.1rem 0rem;
      background-size: 132% 75%;
      background-position: -0.18rem 0.46rem;
      z-index: 99;
    }

    .bg-3 {
      background: url("../../assets/newImages/open-bg3.png") no-repeat;
      background-size: 132% 75%;
      background-position: -0.18rem 0.46rem;
      z-index: 99;
    }

    .bg-4 {
      background: url("../../assets/newImages/open-bg4.png") no-repeat;
      background-size: 132% 75%;
      background-position: -0.18rem 0.46rem;
      z-index: 99;
    }
  }

  .bottom-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.2rem;
    .cancel {
      width: 1.12rem;
      height: 0.3rem;
      background: url("../../assets/newImages/user-itembg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 0.1rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      padding: 0.12rem;
    }

    .confirm {
      width: 1.12rem;
      height: 0.3rem;
      background: url("../../assets/newImages/user-itembg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      font-size: 0.1rem;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
