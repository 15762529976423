<template>
  <div class="get-mod">
    <div class="get-item" v-for="(item, index) in list" :key="index">
      <div class="title">
        <div class="title-l">{{ item.title }}</div>
        <div class="title-r">{{ item.time }}</div>
      </div>
      <div class="info">
        <div class="info-l">{{ item.name }}</div>
        <div class="info-r">{{ item.price }}<span style="color: #ff9900">＄</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.get-mod {
  .get-item {
    width: 100%;
    margin-bottom: 0.16rem;
    .title {
      width: 100%;
      height: 0.41rem;
      background: url(../../../assets/images/classics/table-title.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0.15rem 0 0.54rem;
      font-size: 0.19rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cccccc;
    }
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 0.87rem;
      background: #1c3737;
      font-size: 0.17rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9933ff;
      box-sizing: border-box;
      padding: 0.25rem 0.93rem 0.24rem 0.68rem;
    }
  }
}
</style>
