var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"luckdrawX"},[(_vm.Animation_checked)?_c('div',{staticClass:"openbg"},[_c('div',{staticClass:"prize_box"},[_c('div',{staticClass:"out"},_vm._l((_vm.active),function(i,index){return _c('div',{key:index,staticClass:"prize_box_roll",class:{ active: _vm.StyBk == true },staticStyle:{"display":"flex"},style:(_vm.Topstyle[index]),attrs:{"name":i}},_vm._l((_vm.J_Arr[i - 1]),function(item,index){return _c('div',{key:index,staticClass:"prize_box_roll-item-aitive",class:{ 'prize_box_roll-item': index < _vm.time * 6 },attrs:{"name":item?.siyecao}},[(_vm.isCdkeyBox)?_c('div',{staticClass:"cont_pic",class:`bg-img${item?.lv || item?.level}`},[_c('img',{attrs:{"src":require("@/assets/newImages/moeny-pile.png"),"alt":"","srcset":""}})]):_c('div',{staticClass:"cont_pic",class:`bg-img${item?.lv || item?.level}`},[_c('img',{attrs:{"src":item?.cover,"alt":"","srcset":""}})])])}),0)}),0)])]):_vm._e(),_c('div',{staticClass:"left-float"}),_c('div',{staticClass:"right-float"}),(!_vm.show)?_c('div',{staticClass:"line-float",class:{
      'line-float-5': _vm.active > 3,
      'line-float-2': _vm.active === 2,
      'line-float-3': _vm.active === 3,
    }}):_vm._e(),(_vm.show)?_c('van-overlay',{attrs:{"show":_vm.show},nativeOn:{"mousewheel":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"Show_box2",class:{
          Show_box3: [..._vm.ItemArr, ..._vm.givingItemData].length > 3,
        }},[(_vm.show)?_c('AOpenBox',{attrs:{"openNum":_vm.active,"animationShow":_vm.Animation_checked,"imgList":[..._vm.ItemArr, ..._vm.givingItemData],"isCdkeyBox":_vm.isCdkeyBox},on:{"cancelFun":_vm.Postcash,"confirmFun":_vm.GuanBi}}):_vm._e()],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }